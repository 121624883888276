<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('adminStaff') }}</h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button size="default" @click="addItem()">
                            {{ $t('add') }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

                <el-table-column type="index" :index="indexMethod" width="50" fixed> </el-table-column>
                <el-table-column sortable :label="$t('name')" prop="name" width="200" fixed>
                    <template #default="scope">
                        {{ getTitleLabel(scope.row.title) + ' ' + scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('establishment')" prop="establishment" width="140" fixed>
                    <template #default="scope">
                        {{ getEstablishmentLocation(scope.row.establishment) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('level')" prop="level" width="140">
                    <template #default="scope">
                        {{ getLevelName(scope.row.level) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('role')" prop="role" width="200">
                    <template #default="scope">
                        {{ getRoleLabel(scope.row.role) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('contact')" prop="contact" width="200"> </el-table-column>

                <el-table-column align="right" width="200" fixed="right">

                    <template #header>
                        <el-input v-model="search" size="small" :placeholder="$t('search')" />
                    </template>

                    <template #default="scope">
                        <el-button size="small" @click="editItem(scope.$index, scope.row)">
                            {{ $t('edit') }}
                        </el-button>
                        <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)">
                            {{ $t('delete') }}
                        </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>
        </el-card>

        <el-dialog :title="$t('addStaffMember')" v-model="addDialog" width="65%" top="8vh">

            <el-form
                :model="addStaff"
                :rules="rules"
                :inline="true"
                :ref="addStaffForm"
                label-width="140px"
                label-position="left"
                hide-required-asterisk>

                <el-form-item prop="title" :label="$t('civility')">
                    <el-select
                        v-model="addStaff.title"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in titles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="name" :label="$t('name')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('name')"
                        v-model="addStaff.name"/>
                </el-form-item>

                <el-form-item prop="contact" :label="$t('contact')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('contact')"
                        v-model="addStaff.contact"/>
                </el-form-item>

                <el-form-item prop="establishment" :label="$t('establishment')">
                    <el-select
                        v-model="addStaff.establishment"
                        :placeholder="$t('select')"
                        filterable
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in establishments"
                            :key="item.id"
                            :value="item.id"
                            :label="item.location">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="level" :label="$t('level')">
                    <el-select
                        v-model="addStaff.level"
                        :placeholder="$t('select')"
                        filterable
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in levels"
                            :key="item.id"
                            :value="item.id"
                            :label="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="role" :label="$t('role')">
                    <el-select
                        filterable
                        v-model="addStaff.role"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in roles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(addStaffForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(addStaffForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

        <el-dialog :title="$t('editStaffMember')" v-model="editDialog" width="65%" top="8vh">

            <el-form
                :model="editStaff"
                :rules="rules"
                :inline="true"
                :ref="editStaffForm"
                label-width="140px"
                label-position="left"
                hide-required-asterisk>

                <el-form-item prop="title" :label="$t('civility')">
                    <el-select
                        v-model="editStaff.title"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in titles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="name" :label="$t('name')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('name')"
                        v-model="editStaff.name"/>
                </el-form-item>

                <el-form-item prop="contact" :label="$t('contact')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('contact')"
                        v-model="editStaff.contact"/>
                </el-form-item>

                <el-form-item prop="establishment" :label="$t('establishment')">
                    <el-select
                        v-model="editStaff.establishment"
                        :placeholder="$t('select')"
                        filterable
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in establishments"
                            :key="item.id"
                            :value="item.id"
                            :label="item.location">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="level" :label="$t('level')">
                    <el-select
                        v-model="editStaff.level"
                        :placeholder="$t('select')"
                        filterable
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in levels"
                            :key="item.id"
                            :value="item.id"
                            :label="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="role" :label="$t('role')">
                    <el-select
                        filterable
                        v-model="editStaff.role"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in roles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(editStaffForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(editStaffForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import { StaffRoles, Titles } from '@/utils/enums'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Staff',
    mixins: [accessManager],
    data() {
      return {
        roles: [],
        titles: [],
        levels: [],
        establishments: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        editStaff: {
            title: '',
            name: '',
            contact: '',
            level: '',
            establishment: '',
            role: ''
        },
        addStaff: {
            title: '',
            name: '',
            contact: '',
            level: '',
            establishment: '',
            role: ''
        },
        rules: {
            title: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            name: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            contact: [
                {
                    required: false,
                }
            ],
            level: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            establishment: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            role: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ]
        },
        editStaffForm: 'editStaffForm',
        addStaffForm: 'addStaffForm',
        currentEditItem: null,
        editDialog: false,
        isLoading: false,
        addDialog: false,
        tableData: [],
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

      this.fetchStaff()

      var self = this

      this.$_.forIn(StaffRoles, function(value, key) {

          self.roles.push(value)

      })

      this.$_.forIn(Titles, function(value, key) {

          self.titles.push(value)

      })

    },
    computed:{
      ...mapState({
        userData: state => state.session.userData
      }),
      ...mapGetters({
        getSettingValue: 'settings/getSettingValue'
      }),
      pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

      },
      searching() {

        if (!this.search) {
            this.total = this.tableData.length
            return this.tableData
        }

        this.page = 1

        return this.tableData.filter(data => (
            data.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.contact.toLowerCase().includes(this.search.toLowerCase())
        ))

      }
    },
    methods: {
      ...mapActions({
         fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
         fetchLevelsDB: 'levels/fetchLevelsDB',
         fetchStaffDB: 'staff/fetchStaffDB',
      }),
      setPage (val) {

         this.page = val

      },
      indexMethod(index) {

         return index + ((this.page - 1) * this.pageSize) + 1

      },
      getTitleLabel(id){

          let title = this.titles.find(t => t.id == id)

          return title ? title.label : ''

      },
      getLevelName(id){

          let level = this.levels.find(l => l.id == id)

          return level ? level.name : ''

      },
      getDepartmentName(id){

          let department = this.departments.find(d => d.id == id)

          return department ? department.name : ''

      },
      getEstablishmentLocation(id){

          let establishment = this.establishments.find(e => e.id == id)

          return establishment ? establishment.location : ''

      },
      getRoleLabel(id){

          let role = this.roles.find(f => f.id == id)

          return role ? role.label : ''

      },
      async fetchStaff(){

        if (!this.adminSchoolyear) return

        this.isLoading = true

        let resp4 = await this.fetchEstablishmentsDB({
            action: 'getEstablishments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp4.success && resp4.data) this.establishments = resp4.data

        let resp2 = await this.fetchLevelsDB({
            action: 'getLevels',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp2.success && resp2.data) this.levels = resp2.data

        let resp3 = await this.fetchStaffDB({
            action: 'getStaff',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp3.success){

            if (resp3.data) this.tableData = resp3.data

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        this.isLoading = false

      },
      addItem(){

           if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
           }

           this.addDialog = true

      },
      async editItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.currentEditItem = item

            this.editStaff.name = item.name
            this.editStaff.contact = item.contact
            this.editStaff.level = item.level == '0' ? '' : item.level
            this.editStaff.establishment = item.establishment == '0' ? '' : item.establishment
            this.editStaff.title = parseInt(item.title)
            this.editStaff.role = parseInt(item.role)

            this.editDialog = true

      },
      deleteItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.$confirm(this.$t('deleteStaffQuestion'), this.$t('delete'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
            })
            .then(async () => {

                let task = await this.fetchStaffDB({
                    action: 'deleteStaff',
                    params: {
                        id: item.id
                    }
                })

                if (task.success){

                    this.$message.success(this.$t('success'))
                    this.fetchStaff()

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })
            .catch(() => {

            })

      },
      submitForm(ref){

            this.$refs[ref].validate(async (valid) => {
                if (valid) {

                    if (ref === this.addStaffForm){

                        let task = await this.fetchStaffDB({
                            action: 'addStaff',
                            params: {
                                title: this.addStaff.title,
                                name: this.addStaff.name,
                                contact: this.addStaff.contact,
                                level: this.addStaff.level,
                                establishment: this.addStaff.establishment,
                                role: this.addStaff.role,
                                schoolyear: this.adminSchoolyear
                            }
                        })

                        if (task.success) this.$message.success(this.$t('success'))
                        else this.$message.error(this.$t('anErrorOccured'))

                    }else{

                        let newData = {}

                        if (this.currentEditItem.title !== this.editStaff.title) {
                            newData['title'] = this.editStaff.title
                        }

                        if (this.currentEditItem.name !== this.editStaff.name) {
                            newData['name'] = this.editStaff.name
                        }

                        if (this.currentEditItem.contact !== this.editStaff.contact) {
                            newData['contact'] = this.editStaff.contact
                        }

                        if (this.currentEditItem.level !== this.editStaff.level) {
                            newData['level'] = this.editStaff.level
                        }

                        if (this.currentEditItem.establishment !== this.editStaff.establishment) {
                            newData['establishment'] = this.editStaff.establishment
                        }

                        if (this.currentEditItem.role !== this.editStaff.role) {
                            newData['role'] = this.editStaff.role
                        }

                        if (!this.$_.isEmpty(newData)) {
                            let task = await this.fetchStaffDB({
                                action: 'editStaff',
                                params: {
                                    id: this.currentEditItem.id,
                                    data: newData
                                }
                            })

                            if (task.success) this.$message.success(this.$t('success'))
                            else this.$message.error(this.$t('anErrorOccured'))
                        }

                    }

                    this.cancelForm(ref)
                    this.fetchStaff()

                } else {
                    return false
                }
            })

      },
      cancelForm(ref){

            this.$refs[ref].resetFields()

            if (ref === this.addStaffForm) this.addDialog = false
            else this.editDialog = false

      }
    },
}
</script>